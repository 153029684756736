/* requires:
polyfill.js
*/

// for active menu highlighting
(function(){
  const a = document.getElementById('nav').getElementsByTagName("a");
  // let loc;
  // if (window.location.href.substr(location.href.length - 1, 1) == '/') {
  //   loc = window.location.href + 'index.html';
  // } else {
  //   loc = window.location.href;
  // }
  let loc = window.location.href;
  for(var i=0; i < a.length; i++) {
    if (a[i].href == loc) {
      a[i].classList.add('is-active');
    }
  }
})();

document.getElementById('nav').querySelector('ul').insertAdjacentHTML("beforebegin", "<span id='menutoggle'><span>Menu</span></span>");
var menutoggle = document.getElementById("menutoggle");
var activeClass = "is-active"

menutoggle.onclick = function(event) {
  menutoggle.classList.toggle(activeClass);
  //menutoggle.nextSibling.classList.toggle(activeClass);
  var el = document.querySelectorAll("#nav span.submenu, #nav a.submenu, #nav ul.submenu");
  var i; for (i = 0; i < el.length; i++) {
    el[i].classList.remove(activeClass);
  }
  event.preventDefault();
};

const forEach = function (array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]); // passes back stuff we need from the array
  }
};
forEach(document.querySelectorAll("#nav span.submenu, #nav a.submenu"), function (index, value) {
  value.addEventListener('click', function() {
    if ( menutoggle.offsetWidth > 0 && menutoggle.offsetHeight > 0 ) { // if the #menutoggle is visible
      value.classList.toggle(activeClass);
    }
  })
});

function hideMenu() {
  var el = document.querySelectorAll("#menutoggle, #menutoggle + ul, #nav span.submenu, #nav a.submenu, #nav ul.submenu");
  var i; for (i = 0; i < el.length; i++) {
    el[i].classList.remove(activeClass);
  }
}

document.addEventListener('click', function(e) {
  if ( menutoggle.offsetWidth > 0 && menutoggle.offsetHeight > 0 ) { // if the #menutoggle is visible
    var e=e? e : window.event;
    var eventElement=e.target? e.target : e.srcElement;
    if (!eventElement.closest("#nav")){
      //console.log(event_element.closest("#nav"));
      if (menutoggle.classList.contains(activeClass)) {
        hideMenu();
      }
    }
  }
}, false);

var resizeTimer;
window.addEventListener("resize", function () {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {
    if ( menutoggle.offsetWidth <= 0 && menutoggle.offsetHeight <=  0 ) { // if the #menutoggle is hidden
      hideMenu();
    }
  }, 250);
}, false);
