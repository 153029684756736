/* requires:
polyfill.js
*/

// Dropdown Select Toggle
var activeClass = "is-active"
var forEach = function (array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]); // passes back stuff we need from the array
  }
};
forEach(document.querySelectorAll(".dropdown-list span.dropdown"), function (index, value) {
  value.addEventListener('click', function() {
    //console.log(value.classList);
    if ( !value.classList.contains(activeClass) ) {
      var el = document.querySelectorAll(".dropdown-list span.dropdown");
      var i; for (i = 0; i < el.length; i++) {
        el[i].classList.remove(activeClass);
      }
      value.classList.toggle(activeClass);
    } else
    if ( value.classList.contains(activeClass) ) {
      value.classList.remove(activeClass);
    }
  })
});
document.addEventListener('click', function(e) {
  // Dropdown Select Toggle
  var el = document.querySelectorAll(".dropdown-list span.dropdown")
  var e=e? e : window.event;
  var eventElement=e.target? e.target : e.srcElement;
  if (!eventElement.closest(".dropdown-list")){
    //console.log(event_element.closest(".dropdown-list"));
    var i; for (i = 0; i < el.length; i++) {
      el[i].classList.remove(activeClass);
    }
  }
}, false);
